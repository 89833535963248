import React from 'react';
import aboutimg from '../assets/img/aboutimg.jpeg';
import Footer from './footer';
import Header from './header';
import Menubar from './menubar';

function Salgsvilkar() {
  return (
    <>
        <Menubar />
        <Header />
        <div className='w3-content'>
            <h5>SALGSVILKÅR</h5>
            <b>Betaling</b>
            <p>
              Selgeren (Kjoks Kunst, Sandvedbøen 142, 4320 Sandnes, kontakt@kjoks.no, orgnr. 929730151) kan kreve betaling for varen fra det tidspunkt den blir sendt fra selgeren til kjøperen.
              Dersom kjøperen bruker kredittkort eller debetkort ved betaling, kan selgeren reservere kjøpesummen på kortet ved bestilling. Kortet blir belastet samme dag som varen sendes.
            </p>
            
            <b>Levering</b>
            <p>
              Levering er skjedd når kjøperen, eller hans representant, har overtatt tingen.
              <br />
              <br />
              Hvis ikke leveringstidspunkt fremgår av bestillingsløsningen, skal selgeren levere varen til kjøper uten unødig opphold og senest 30 dager etter bestillingen fra kunden. Varen skal leveres hos kjøperen med mindre annet er særskilt avtalt mellom partene.
            </p>
            
            <b>Angrerett </b>
            <p>
              Med mindre avtalen er unntatt fra angrerett, kan kjøperen angre kjøpet av varen i henhold til angrerettloven.
              Kjøperen må gi selger melding om bruk av angreretten innen 14 dager fra fristen begynner å løpe. I fristen inkluderes alle kalenderdager. Dersom fristen ender på en lørdag, helligdag eller høytidsdag forlenges fristen til nærmeste virkedag.
              Angrefristen anses overholdt dersom melding er sendt før utløpet av fristen. Kjøper har bevisbyrden for at angreretten er blitt gjort gjeldende, og meldingen bør derfor skje skriftlig (angrerettskjema, e-post eller brev).
              Angrefristen begynner å løpe:<br />
              <br />              
                  •	Ved kjøp av enkeltstående varer vil angrefristen løpe fra dagen etter varen(e) er mottatt.<br />
                  •	Består kjøpet av flere leveranser, vil angrefristen løpe fra dagen etter siste leveranse er mottatt.<br />
              <br />
              Ved bruk av angreretten må varen leveres tilbake til selgeren uten unødig opphold og senest 14 dager fra melding om bruk av angreretten er gitt. Kjøper dekker de direkte kostnadene ved å returnere varen. Selgeren kan ikke fastsette gebyr for kjøperens bruk av angreretten.<br />
              <br />
              Selgeren er forpliktet til å tilbakebetale kjøpesummen til kjøperen uten unødig opphold, og senest 14 dager fra selgeren fikk melding om kjøperens beslutning om å benytte angreretten. Selger har rett til å holde tilbake betalingen til han har mottatt varene fra kjøperen, eller til kjøper har lagt frem dokumentasjon for at varene er sendt  tilbake.
            </p>
            
            <b>Mangel ved varen - kjøperens rettigheter og reklamasjonsfrist</b>
            <p>
              Hvis det foreligger en mangel ved varen må kjøper innen rimelig tid etter at den ble oppdaget eller burde ha blitt oppdaget, gi selger melding om at han eller hun vil påberope seg mangelen. Kjøper har alltid reklamert tidsnok dersom det skjer innen 2 mnd. fra mangelen ble oppdaget eller burde blitt oppdaget. Reklamasjon kan skje senest to år etter at kjøper overtok varen. Dersom varen eller deler av den er ment å vare vesentlig lenger enn to år, er reklamasjonsfristen fem år.<br />
              <br />
              Dersom varen har en mangel og dette ikke skyldes kjøperen eller forhold på kjøperens side, kan kjøperen i henhold til reglene i forbrukerkjøpsloven kapittel 6 etter omstendighetene holde kjøpesummen tilbake, velge mellom retting og omlevering, kreve prisavslag, kreve avtalen hevet og/eller kreve erstatning  fra selgeren.
              Reklamasjon til selgeren bør skje skriftlig.
            </p>

            
            <p>
              <i>Retting eller omlevering</i><br />
              Kjøperen kan velge mellom å kreve mangelen rettet eller levering av tilsvarende ting. Selger kan likevel motsette seg kjøperens krav dersom gjennomføringen av kravet er umulig eller volder selgeren urimelige kostnader. Retting eller omlevering skal foretas innen rimelig tid. Selger har i utgangspunktet ikke rett til å foreta mer enn to avhjelpsforsøk for samme mangel.
            </p>

            <p>
              <i>Prisavslag</i><br />
              Kjøper kan kreve et passende prisavslag dersom varen ikke blir rettet eller omlevert. Dette innebærer at forholdet mellom nedsatt og avtalt pris svarer til forholdet mellom tingens verdi i mangelfull og kontraktsmessig stand. Dersom særlige grunner taler for det, kan prisavslaget i stedet settes lik mangelens betydning for kjøperen.
            </p>

            
            <p>
              <i>Heving</i><br />
              Dersom varen ikke er rettet eller omlevert, kan kjøperen også heve kjøpet når mangelen ikke er uvesentlig.
            </p>

            <b>Konfliktløsning</b>
            <p>
              Klager rettes til selger innen rimelig tid.  Partene skal forsøke å løse eventuelle tvister i minnelighet. Dersom dette ikke lykkes, kan kjøperen ta kontakt med Forbrukerrådet for mekling. Forbrukerrådet er tilgjengelig  på  telefon 23  400  500  eller www.forbrukerradet.no.
            </p>
        </div>
        <Footer />
    </>
  );
}

export default Salgsvilkar;