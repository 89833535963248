import "./App.css";
import Art from "./components/art";
import Header from "./components/header";
import Menubar from "./components/menubar";
import Footer from "./components/footer";
import Contact from "./components/contact";
import useImgZoomModal from "./hooks/useImgZoomModal";
import ImgZoomModal from "./components/img-zoom-modal";
import { Link } from "react-router-dom";

function App() {
	const { activeImg, toggle } = useImgZoomModal();

	console.log("activeImg", activeImg);

	return (
		<div className="App">
			<ImgZoomModal activeImg={activeImg} setActiveImg={toggle} />
			<Menubar />
			<Header />
			<div className="w3-content">
				<Art toggleModal={toggle} />
				{/* <About /> */}´
				<Contact />
			</div>
			<Footer />
		</div>
	);
}

export default App;
