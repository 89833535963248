import React from 'react';
import headerimg from '../assets/img/headerimg.jpeg';

function Header() {
  return (
    <header className="w3-display-container w3-content w3-wide" style={{margin: "0px", maxWidth:"100%", maxHeight:"900px", overflow:"hidden"}} id="home">
        <img className="w3-image" src={headerimg} alt="kjoks" width="100%" height="800" style={{marginTop: "54.5px"}} />
        <div className="w3-display-bottomleft w3-padding-large w3-opacity">
            <h1 style={{fontSize: "2.5vw"}}>Galleri</h1>
        </div>
    </header>
  );
}

export default Header;