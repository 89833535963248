import React from 'react';
import { Link } from "react-router-dom";

function Contact() {
  return (
    <div className="w3-container w3-padding-64 w3-center" id="contact">
      <div className="blackBar"></div>
      <h1>Kontakt</h1><br />
      <p>Vil du kjøpe et maleri, ha mer informasjon eller bare diskutere kunst, ta gjerne kontakt.</p>
      <p className="w3-text-blue-grey w3-large"><b>Utstilling tilgjengelig ved avtale, jeg holder til i Sandnes.</b></p>
      <p>Du kan kontakte meg på telefon <a href="tel:93481704">934 81 704</a> eller på email <a href="mailto:kontakt@kjoks.no">kontakt@kjoks.no</a>.</p>
      {/* <form action="/action_page.php" target="_blank">
          <p><input className="w3-input w3-padding-16" type="text" placeholder="Navn" required name="Name" /></p>
          <p><input className="w3-input w3-padding-16" type="text" placeholder="Melding" required name="Message" /></p>
          <p><button className="w3-button w3-light-grey w3-section" type="submit">SEND MELDING</button></p>
      </form> */}
      <Link to="/salgsvilkar">Salgsvilkår</Link>
    </div>
  );
}

export default Contact;