function Menubar() {
  return (
    <div className="w3-top">
      <div className="w3-bar w3-white w3-padding w3-card" style={{letterSpacing:"4px"}}>
        <a href="/#home" className="w3-bar-item w3-button w3-center-small">Kjoks Kunst</a>
        <div className="w3-right w3-hide-small">
          <a href="/#home" className="w3-bar-item w3-button">Hjem</a>
          <a href="/#paintings" className="w3-bar-item w3-button">Malerier</a>
          {/* <a href="#about" className="w3-bar-item w3-button">Om</a> */}
          {//<a href="#menu" className="w3-bar-item w3-button"></a>
          }
          <a href="/#contact" className="w3-bar-item w3-button">Kontakt</a>
        </div>
      </div>
    </div>
  );
}

export default Menubar;

