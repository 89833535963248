import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
//import firebaseConfig from "./firebase/firebaseConfig.tsx";
import Salgsvilkar from "./components/salgsvilkar";

// Initialize Firebase
//const app = initializeApp(firebaseConfig.firebaseConfig);
//const analytics = getAnalytics(app);

ReactDOM.render(
	// <React.StrictMode>
	//   <App />
	// </React.StrictMode>,
	<BrowserRouter>
		<Routes>
			<Route path="/" element={<App />} />
			<Route path="salgsvilkar" element={<Salgsvilkar />} />
		</Routes>
	</BrowserRouter>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
