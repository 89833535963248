import React from "react";
import ReactDOM from "react-dom";

interface ImgZoomModalProps {
	//imageId:string,
	activeImg: string;
	setActiveImg: (activeImgInput: string) => void;
}

/*<div id="myModal" class="modal">
  <span class="close">&times;</span>
  <img class="modal-content" id="img01">
  <div id="caption"></div>
</div> */

const ImgZoomModal = (props: ImgZoomModalProps) =>
	props.activeImg != ""
		? ReactDOM.createPortal(
				<React.Fragment>
					<div
						id="myModal"
						className="modal"
						onClick={() => props.setActiveImg("")}
					>
						<span
							className="close"
							onClick={() => props.setActiveImg("")}
						>
							&times;
						</span>
						{
							<img
								src={props.activeImg}
								className="modal-content"
							/>
						}
					</div>
				</React.Fragment>,
				document.body
		  )
		: null;

export default ImgZoomModal;
