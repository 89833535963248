import { useState } from 'react';

const useImgZoomModal = () => {
  const [activeImg, setActiveImg] = useState("");

  function toggle(activeImgInput:string) {
    setActiveImg(activeImgInput == activeImg ? "" : activeImgInput);
  }

  return {
    activeImg,
    toggle,
  }
};

export default useImgZoomModal;