// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: "AIzaSyAK6Y3y6zyznnUtNuH4yK6F6OCsqZ2q6BU",
	authDomain: "kjoks-e0599.firebaseapp.com",
	projectId: "kjoks-e0599",
	storageBucket: "kjoks-e0599.appspot.com",

	messagingSenderId: "1015185742327",
	appId: "1:1015185742327:web:0cf4d7378262e3330d36f2",
};
// Initialize Firebase

const app = initializeApp(firebaseConfig);
// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app);

// Initialize Firebase
const analytics = getAnalytics(app);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

// {
//     "firebaseConfig": {
//         "apiKey": "AIzaSyAK6Y3y6zyznnUtNuH4yK6F6OCsqZ2q6BU",
//         "authDomain": "kjoks-e0599.firebaseapp.com",
//         "projectId": "kjoks-e0599",
//         "storageBucket": "kjoks-e0599.appspot.com",
//         "messagingSenderId": "1015185742327",
//         "appId": "1:1015185742327:web:0cf4d7378262e3330d36f2",
//         "measurementId": "G-YMRXP21VB7"
//     }
// }
