import { useEffect, useState } from "react";
import Banner from "./banner";
import ImgZoomModal from "./img-zoom-modal";

interface ArtProps {
	src: string;
	size_text: string;
	alt: string;
	price: string;
	description: string;
	sold: boolean;
	bannerText: string;
	bannerColor: string;
	toggleModal: (activeImgInput: string) => void;
}

function ArtItem(props: ArtProps) {
	console.log(props);
	return (
		<>
			<div className="w3-col s6 m4 l3">
				<div className="artItemContainer">
					<a
						onClick={() =>
							props.toggleModal(
								props.src.replace(
									props.alt,
									props.alt + "_medium"
								)
							)
						}
					>
						<img
							src={props.src}
							alt={props.alt}
							style={{ width: "100%" }}
						/>
					</a>
					{!props.sold && props.bannerText !== "" && (
						<Banner
							text={props.bannerText}
							color={props.bannerColor}
						/>
					)}
					{props.sold && <Banner text="Solgt" color="#de847e" />}
				</div>
				<div className="artMetaDataContainer">
					<h3>{props.price}</h3>
					<h6 id="measurement" className="w3-center">
						{props.size_text}
					</h6>
				</div>
			</div>
		</>
	);
}

export default ArtItem;
