interface BannerProps {
    text:string,
    color:string
}

function Banner(props:BannerProps) {
    return (
    <div className="banner" style={{backgroundColor:props.color}}>
        {props.text}
    </div>
    );
}

export default Banner;