

import React from 'react';

function Footer() {
  return (
    <footer className="w3-center w3-light-grey w3-padding-32">
        <a href="http://www.instagram.com/kjokskunst" className="fa fa-instagram w3-center"></a>
    </footer>
  );
}

export default Footer;